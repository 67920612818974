//
//
//
//

import { mapState } from 'vuex';
import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';
import { publishedContentCounts, renderContentCount } from '@motionelements/core/src/helpers/contentCount.js';

export default {
  name: 'HomeSchema',
  mixins: [channelTypesMixin],
  computed: {
    ...mapState({
      languages: state => state.app.languages,
    }),
    pageTitle() {
      return this.$schema.getPageByLang('home', this.$i18n.locale).title;
    },
    pageDescription() {
      return this.$schema.getPageByLang('home', this.$i18n.locale).description;
    },
    organizationJsonLd() {
      const knowsLanguage = this.languages.map(({ name }) => name);
      const offerCatalogList = this.channelTypesFiltered.map(({ id }) => ({
        '@type': 'Offer',
        'name': this.$term('mediaType', id).plural().text(),
      }));
      const jsonLd = {
        '@context': 'https://schema.org',
        '@type': [
          'OnlineBusiness',
          'Organization',
        ],
        'name': 'MotionElements',
        'description': this.pageDescription, // "MotionElements is a micro-stock marketplace for video creators. It offers a fully localized experience for stock media buyers and sellers across Asia and the world. The vibrant marketplace offers everything from stock videos, music, 3D models, After Effects and Apple Motion templates bundled with MotionElements’ transparent, royalty-free licensing.",
        'legalName': 'MotionElements Pte Ltd',
        'url': 'https://www.motionelements.com/',
        'logo': 'https://static.motionelements.com/img/logo/MotionElements-logomark-square-512x512.png',
        'image': {
          '@type': 'ImageObject',
          'url': 'https://static.moele.me/img/logo/MotionElements-logo-masthead-220503.svg',
          'contentUrl': 'https://static.moele.me/img/logo/MotionElements-logo-masthead-220503.svg',
        },
        'email': 'support@motionelements.com',
        'knowsLanguage': knowsLanguage,
        'potentialAction': {
          '@type': 'SubscribeAction',
          'agent': {
            '@type': [
              'Organization',
              'OnlineBusiness',
            ],
            'name': 'MotionElements',
          },
          'object': {
            '@type': 'Thing',
            'name': 'Unlimited downloads for US$16.50/month*',
            'description': this.$t('subscription.access_{count}_content_text', { count: renderContentCount(this, publishedContentCounts.total.subscription.element) }),
            'disambiguatingDescription': this.$t('subscription.billed_annually_text'),
          },
        },
        'hasOfferCatalog': {
          '@type': 'OfferCatalog',
          'name': this.$t('home.hero_heading'), // "Royalty-free stock footage, music, templates for video creators",
          'itemListElement': offerCatalogList,
        },
        'slogan': this.$t('home.hero_subheading'),
        'subjectOf': {
          '@id': 'HP',
        },
        'founder': [
          {
            '@type': 'Person',
            'name': 'Mark Sun',
          },
          {
            '@type': 'Person',
            'name': 'Joanne Chua',
          },
        ],
        'department': [
          {
            '@type': [
              'OnlineBusiness',
              'Organization',
            ],
            'name': 'Singapore Office',
            'address': {
              '@type': 'PostalAddress',
              'addressCountry': 'SG',
              'postalCode': '069046',
              'streetAddress': '20 McCallum Street, #19-01 Tokio Marine Centre',
            },
          },
          {
            '@type': [
              'OnlineBusiness',
              'Organization',
            ],
            'name': 'Japan Office',
            'address': '東京都港区赤坂 1-12-32 アーク森ビル3F 〒107-6003',
          },
          {
            '@type': [
              'OnlineBusiness',
              'Organization',
            ],
            'name': 'Taiwan Office',
            'address': '新加坡商動態素材科技有限公司台灣分公司 台灣 106 台北市 大安區 忠孝東路四段 169號 12樓',
          },
        ],
        'address': [
          {
            '@type': 'PostalAddress',
            'addressCountry': 'SG',
            'postalCode': '069046',
            'streetAddress': '20 McCallum Street, #19-01 Tokio Marine Centre',
          },
        ],
        'contactPoint': [
          {
            '@type': 'ContactPoint',
            'email': 'support@motionelements.com',
            'url': 'https://help.motionelements.com/',
            'contactType': 'customer support',
          },
        ],
        'sameAs': [
          'https://twitter.com/motionelements',
          'https://www.facebook.com/motionelements',
          'https://www.instagram.com/motionelements/',
          'https://www.linkedin.com/company/motionelements',
          'https://www.youtube.com/user/MotionElements',
        ],
      };

      return {
        once: true,
        hid: 'organization-ld-json',
        type: 'application/ld+json',
        json: jsonLd,
      };
    },
    searchActionJsonLd() {
      const searchUrls = this.channelTypesFiltered.map(({ id }) => this.$link(`https://www.motionelements.com/search/${id}?s={search_term_string}&sort=match`).url());

      const jsonLd = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        'url': 'https://www.motionelements.com/',
        'potentialAction':
            {
              '@type': 'SearchAction',
              'target':
                  {
                    '@type': 'EntryPoint',
                    'urlTemplate': searchUrls,
                    // [
                    //   "https://www.motionelements.com/search/video?s={search_term_string}&sort=match",
                    //   "https://www.motionelements.com/search/music?s={search_term_string}&sort=match",
                    //   "https://www.motionelements.com/search/ae?s={search_term_string}&sort=match",
                    //   "https://www.motionelements.com/search/pr?s={search_term_string}&sort=match",
                    //   "https://www.motionelements.com/search/fcp?s={search_term_string}&sort=match",
                    //   "https://www.motionelements.com/search/resolve?s={search_term_string}&sort=match",
                    //   "https://www.motionelements.com/search/image?s={search_term_string}&sort=match",
                    //   "https://www.motionelements.com/search/sfx?s={search_term_string}&sort=match",
                    //   "https://www.motionelements.com/search/3d?s={search_term_string}&sort=match",
                    //   "https://www.motionelements.com/search/3d?s={search_term_string}&sort=match"
                    // ]
                  },
              'query-input': 'required name=search_term_string',
            },
      };

      return {
        once: true,
        hid: 'website-ld-json',
        type: 'application/ld+json',
        json: jsonLd,
      };
    },
    navJsonLd() {
      const headerNavItems = [];
      const footerNavItems = [];

      this.channelTypesFiltered.forEach(item => {
        headerNavItems.push({
          '@context': 'https://schema.org',
          '@type': 'SiteNavigationElement',
          '@id': '#Explore',
          'name': this.getChannelTypeName(item.id, false),
          'url': this.$link(item.link).url(),
        });
        footerNavItems.push({
          '@context': 'https://schema.org',
          '@type': 'SiteNavigationElement',
          '@id': '#Media',
          'name': this.getChannelTypeName(item.id, false),
          'url': this.$link(item.link).url(),
        });
      });

      const jsonLd = {
        '@context': 'https://schema.org',
        '@graph':
            [
              // header
              {
                '@context': 'https://schema.org',
                '@type': 'WPHeader',
                '@id': '#h',
                'name': this.$t('nav.explore'),
              },
              ...headerNavItems,
              {
                '@context': 'https://schema.org',
                '@type': 'WPHeader',
                '@id': '#h',
                'name': this.$t('nav.for_creators'),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#creators',
                'name': this.$t('nav.eula'),
                'url': this.$link('/legal/license').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#creators',
                'name': this.$t('header.user_guide'),
                'url': this.$link('/begin').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#creators',
                'name': this.$t('nav.pricing'),
                'url': this.$link('/pricing').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#creators',
                'name': this.$t('term.unlimited_subscription'),
                'url': this.$link('/subscribe').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#creators',
                'name': this.$t('nav.help_center'),
                'url': this.$link('https://help.motionelements.com/hc/en/').url(),
              },
              // footer
              {
                '@context': 'https://schema.org',
                '@type': 'WPFooter',
                '@id': '#F',
                'name': this.$t('term.media_types'),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'WPFooter',
                '@id': '#F',
                'name': this.$t('nav.for_creators'),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'WPFooter',
                '@id': '#F',
                'name': this.$t('nav.for_contributors'),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'WPFooter',
                '@id': '#F',
                'name': this.$t('footer.free'),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'WPFooter',
                '@id': '#F',
                'name': 'MotionElements®',
              },
              ...footerNavItems,
              // {
              //   "@context": "https://schema.org",
              //   "@type":"SiteNavigationElement",
              //   "@id":"#Media",
              //   "name": "Videos",
              //   "url": "https://www.motionelements.com/video"
              // },
              // {
              //   "@context": "https://schema.org",
              //   "@type":"SiteNavigationElement",
              //   "@id":"#Media",
              //   "name": "Music",
              //   "url": "https://www.motionelements.com/music"
              // },
              // {
              //   "@context": "https://schema.org",
              //   "@type":"SiteNavigationElement",
              //   "@id":"#Media",
              //   "name": "Sound effects",
              //   "url": "https://www.motionelements.com/sound-effects"
              // },
              // {
              //   "@context": "https://schema.org",
              //   "@type":"SiteNavigationElement",
              //   "@id":"#Media",
              //   "name": "Image & GIF",
              //   "url": "https://www.motionelements.com/stock-image"
              // },
              // {
              //   "@context": "https://schema.org",
              //   "@type":"SiteNavigationElement",
              //   "@id":"#Media",
              //   "name": "3D",
              //   "url": "https://www.motionelements.com/3d"
              // },
              // {
              //   "@context": "https://schema.org",
              //   "@type":"SiteNavigationElement",
              //   "@id":"#Media",
              //   "name": "Lottie",
              //   "url": "https://www.motionelements.com/lottie"
              // },
              // {
              //   "@context": "https://schema.org",
              //   "@type":"SiteNavigationElement",
              //   "@id":"#Media",
              //   "name": "After Effects",
              //   "url": "https://www.motionelements.com/after-effects-templates"
              // },
              // {
              //   "@context": "https://schema.org",
              //   "@type":"SiteNavigationElement",
              //   "@id":"#Media",
              //   "name": "Premiere Pro",
              //   "url": "https://www.motionelements.com/premiere-pro-templates"
              // },
              // {
              //   "@context": "https://schema.org",
              //   "@type":"SiteNavigationElement",
              //   "@id":"#Media",
              //   "name": "FCP & Motion",
              //   "url": "https://www.motionelements.com/apple-motion-templates"
              // },
              // {
              //   "@context": "https://schema.org",
              //   "@type":"SiteNavigationElement",
              //   "@id":"#Media",
              //   "name": "Resolve",
              //   "url": $link('/davinci-resolve-templates').url(),
              // },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#CR-T',
                'name': this.$t('nav.eula'),
                'url': this.$link('/legal/license').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#CR-T',
                'name': this.$t('header.user_guide'),
                'url': this.$link('/begin').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#CR-T',
                'name': this.$t('nav.pricing'),
                'url': this.$link('/pricing').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#CR-T',
                'name': this.$t('header.subscription'),
                'url': this.$link('/subscribe').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#CR-T',
                'name': this.$t('nav.help_center'),
                'url': this.$link('https://help.motionelements.com/hc/').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#cont',
                'name': this.$t('nav.sell_your_content'),
                'url': this.$link('/contributors').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#cont',
                'name': this.$t('nav.contributor_help_center'),
                'url': this.$link('https://artists.motionelements.com/hc/').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#free',
                'name': this.$t('nav.free_downloads'),
                'url': this.$link('/free/stock-footage').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#free',
                'name': this.$t('nav.banner_generator'),
                'url': this.$link('/banner').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#free',
                'name': this.$t('nav.free_video_converter'),
                'url': this.$link('/video-converter').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#motionel',
                'name': this.$t('nav.about'),
                'url': this.$link('/about').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#motionel',
                'name': this.$t('nav.blog'),
                'url': this.$link('/blog').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#motionel',
                'name': this.$t('nav.affiliate_program'),
                'url': this.$link('/referral').url(),
              },
              {
                '@context': 'https://schema.org',
                '@type': 'SiteNavigationElement',
                '@id': '#motionel',
                'name': this.$t('nav.api'),
                'url': this.$link('/developer').url(),
              },
            ],
      };

      return {
        type: 'application/ld+json',
        json: jsonLd,
      };
    },
  },
  head() {
    return process.server ? {
      script: [
        this.organizationJsonLd,
        this.searchActionJsonLd,
        this.navJsonLd,
      ],
    } : {};
  },
};
